import axios from "axios"

export default class Scorm {

  constructor() {
    let api = axios.create({
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })

    window.API.on("LMSSetValue.cmi.suspend_data", function(CMIElement, value) {
      var simplifiedObject = window.API.cmi.toJSON()
      axios.post(window.pageData.endpoint, simplifiedObject)
    });
  }

}
