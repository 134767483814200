export default class Home {

  constructor() {
    let form: HTMLFormElement = document.querySelector('form#oofm')

    form?.addEventListener("submit", (e) => {
      e.preventDefault()

      let province = form.querySelector('select').value
      let defaultOrientationUrl = form.querySelector('[name=default-orientation-url]').value
      let albertaOrientationUrl = form.querySelector('[name=alberta-orientation-url]').value
      let url = province != 'AB' ? defaultOrientationUrl : albertaOrientationUrl
      window.location.href = url
    })
  }

}