import "../node_modules/simplify-scorm/src/init.js"
import "../node_modules/simplify-scorm/src/constants.js"
import "../node_modules/simplify-scorm/src/baseAPI.js"
import "../node_modules/simplify-scorm/src/jsonFormatter.js"
import "../node_modules/simplify-scorm/src/scormAPI.js"

import Home from "./Home"
import Scorm from "./Scorm"

let types: {[key: string]: any} = {}
types.home = Home
types.scorm = Scorm

document.addEventListener('DOMContentLoaded', () => {
  let Controller = types[window.pageData?.type]
  if(Controller) {
    new Controller()
  }
})